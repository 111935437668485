// based on:
// https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65

import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

// apollo + graphql
// https://hasura.io/learn/graphql/vue/apollo-client/
import VueApollo from "vue-apollo";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

// imports
import ReactiveSearch from '@appbaseio/reactivesearch-vue'
import VueYoutube from 'vue-youtube'
import {VueMasonryPlugin} from 'vue-masonry';

Vue.config.productionTip = false

// use plugins
Vue.use(ReactiveSearch)
Vue.use(VueYoutube)
Vue.use(VueMasonryPlugin)

// apollo setup
Vue.use(VueApollo);

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0);

const getHeaders = () => {
  const headers = {};
   const token = window.localStorage.getItem('apollo-token');
   if (token) {
     headers.authorization = `Bearer ${token}`;
   }
   return headers;
};

// Create an http link:
const link = new HttpLink({
//   uri: 'http://folkme.local/api',
   uri: process.env.VUE_APP_GQL_API_ENDPOINT,
   fetch,
   headers: getHeaders()
});

const client = new ApolloClient({
   link: link,
   cache: new InMemoryCache({
     addTypename: true
   })
});

const apolloProvider = new VueApollo({
  defaultClient: client,
})

// custom mixins
Vue.mixin({
  methods: {
    taxonomiesToString: function (taxonomy, separator='; ') {
      var ret = ''
      var separatorLength = separator.length;
      if (separator == '_newline_') {
        separator = '<br/>'
        separatorLength = 0
      }
      
      if (typeof (taxonomy.level_1) !== 'undefined') {
        for (let level1 of taxonomy.level_1) {
          if (typeof (level1.level_2) !== 'undefined') {
            for (let level2 of level1.level_2) {
              if (typeof (level2.level_3) !== 'undefined') {
                for (let level3 of level2.level_3) {
                  ret += level1.name + ' / ' + level2.name + ' / ' + level3.name + separator
                }
              } else {
                ret += level1.name + ' / ' + level2.name + separator
              }
            }
          } else {
            ret += level1.name + separator
          }
        }
      }
      ret = ret.substring(0, ret.length - separatorLength)
      return ret
    },
    finalLocation: function (location_tree) {
      var ret = ''
      
      if (typeof (location_tree.level_1) !== 'undefined') {
        for (let level1 of location_tree.level_1) {
          if (typeof (level1.level_2) !== 'undefined') {
            for (let level2 of level1.level_2) {
              if (typeof (level2.level_3) !== 'undefined') {
                for (let level3 of level2.level_3) {
                  ret = level3.name
                }
              } else {
                ret = level2.name
              }
            }
          } else {
            ret = level1.name
          }
        }
      }
      return ret
    },
    spacer () {
      return '<img src="https://img.spacergif.org/v1/spacer.gif" width="1" height="1">';
    },
    ucfirst (string) {
      if (string !== null) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    }
  }
})


new Vue({
  i18n,
  vuetify,
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
