<template>
  <div>
    <v-footer
      app
      :padless="true"
      absolute
    >
      <v-card
        flat
        tile
        width="100%"
        class="grey lighten-5 text-center"
      >
        <v-card-text>

        <v-container style="max-width: 1000px; padding-bottom: 0">
          <v-row justify="center">
            <v-col cols="6" xs="6" sm="6" md="4" class="d-flex align-center partner">
                    <a href="https://hagyomanyokhaza.hu/" target="_blank">
                      <v-img src="/img/hh_logo.png" height="80" contain></v-img>
                    </a>
            </v-col>
            <v-col cols="6" xs="6" sm="6"  md="2" class="d-flex align-center partner">
                    <a href="https://www.polyphonyproject.com/" target="_blank">
                      <v-img src="/img/polyphony.png" height="70" contain></v-img>
                    </a>
            </v-col>
          </v-row>
        </v-container>


        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="">
          &copy; {{ new Date().getFullYear() }} — {{ footerText }}
        </v-card-text>
      </v-card>        
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    footerText () {
      const locale = this.$route.params.locale
      if (locale == 'hu') return 'Hagyományok Háza'
      return 'Hungarian Heritage House'
    }
  }
}
</script>


<style scoped>
.partner a {
  max-width: 100%;
}
</style>