<!--
 TODO

 - make the links inside v-tab fill the tab conmpletely
 - rework LocaleSwitcher
 - add mobile navigation drawer

-->

<template>
  <v-app-bar
    app
    dark
    src="/img/folk_me_cover.jpg"
  >
    <v-app-bar-nav-icon @click="drawer = !drawer" class="d-flex d-md-none"></v-app-bar-nav-icon>
    <v-toolbar-title><a href="/" style="text-decoration: none;" class="appname ml-3 mr-5"><img src="/img/logo_folk_me.png" style="height: 48px; margin-top: 15px;" /></a></v-toolbar-title>

    <template v-slot:extension>
      <v-tabs align-with-title class="d-none d-md-flex headerTabs">
        <template v-for="(item, i) in items">
          <v-tab
            v-if="typeof item.params !== 'undefined'"
            :key="i"
          >
            <router-link :to="{ name: 'page', params: item.params[$i18n.locale]}">
                {{ $t(item.text) }}
            </router-link>
          </v-tab>

          <v-tab
            v-else
            :key="i"
          >
              <LocalizedLink :to="item.url" class="tabLink">{{ $t(item.text) }}</LocalizedLink>
          </v-tab>
        </template>
      </v-tabs>
    </template>

    <div class="flex-grow-1"></div>

    <v-img contain v-on:click.native="jumpToLang('/en')" src="/img/en_flag.png" max-height="30" max-width="70"></v-img>
    <v-img contain v-on:click.native="jumpToLang('/hu')" src="/img/hu_flag.png" max-height="30" max-width="70"></v-img>

    <!--
    <LocaleSwitcher />
    -->
  </v-app-bar>
</template>

<script>
// import LocaleSwitcher from "@/components/LocaleSwitcher"
import LocalizedLink from "@/components/LocalizedLink"

export default {
  data: () => ({
    drawer: false,
    items: [
      { text: 'nav.records', url: 'records' },
      { text: 'nav.introduction', url: 'page', params: {hu: {slug: 'bemutatkozas'}, en: {slug: 'introduction'}} },
      { text: 'nav.impressum', url: 'page', params: {hu: {slug: 'impresszum'}, en: {slug: 'impressum'}} }
    ]
  }),
  methods: {
    jumpToLang (langcode) {
      window.location.href = langcode
    }
  },
  components: { 
    // LocaleSwitcher, 
    LocalizedLink 
  }
}
</script>

<style scoped>
.headerTabs .v-tab {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkmAkAAJ8AmzsOXcYAAAAASUVORK5CYII=);
}
.v-tab a {
  color: white;
  text-decoration: none;
  height: 100%;
  padding-top: 14px;  
}
a.appname {
  font-size: 32px;
  color: white !important;
}
</style>
